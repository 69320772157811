import AppConfig from '@/config/appConfig';
import TimeService from "../services/timeService";
import {i18n} from '../internationalization/index';
import ModelUtils from "../utils/ModelUtils";

function isValidNumber(val) {
  return !isNaN(parseFloat(val));
}

const geozoneCreationDetailsFields = [
  {
    id: 'country',
    title: 'labels.geozone.country',
    propName: 'country',
    isMandatory: true,
    isDropDown: true,
    options: AppConfig.geozoneCountries.map(value => {
      return {name: value, value: value};
    }),
    isValid(country) {
      return country && country !== '';
    },
    getValue(model) {
      return model.country;
    },
    setValue(model, value) {
      model.country = value;
    }
  },
  {
    id: 'reason',
    title: 'labels.common.reason',
    propName: 'reason',
    isMandatory: true,
    isDropDown: true,
    options: [
      {name: 'labels.geozone.airTraffic', value: 'AIR_TRAFFIC'},
      {name: 'labels.geozone.sensitive', value: 'SENSITIVE'},
      {name: 'labels.geozone.privacy', value: 'PRIVACY'},
      {name: 'labels.geozone.population', value: 'POPULATION'},
      {name: 'labels.geozone.nature', value: 'NATURE'},
      {name: 'labels.geozone.noise', value: 'NOISE'},
      {name: 'labels.geozone.foreignTerritory', value: 'FOREIGN_TERRITORY'},
      {name: 'labels.geozone.emergency', value: 'EMERGENCY'},
      {name: 'labels.geozone.other', value: 'OTHER'},
    ],
    isValid(reason) {
      return reason && reason !== '';
    },
    getValue(model) {
      return model.reason[0];
    },
    setValue(model, value) {
      model.reason = [];
      model.otherReasonInfo = 'OTHER' === value ? '' : null;
      model.reason.push(value);
    }
  },
  {
    id: 'otherReason',
    title: 'labels.geozone.otherFreetextReason',
    propName: 'otherReasonInfo',
    isMandatory: false,
    isValid(otherReason) {
      return otherReason === null || (otherReason && otherReason !== '' && otherReason.length <= 200);
    },
    getValue(model) {
      return model.otherReasonInfo;
    },
    setValue(model, value) {
      model.otherReasonInfo = value;
    },
    isDisabled(model) {
      return model.reason[0] !== 'OTHER';
    }
  },
  {
    id: 'lowerLimit',
    title: () => {
      return i18n.global.t('labels.common.minAltitude') + ' (' + ModelUtils.constructDisplayedUomAndCrs() + ')';
    },
    propName: 'geometry[0].lowerLimit',
    isMandatory: true,
    isValid(lowerLimit, model) {
      return validateAltitudeField(lowerLimit, model);
    },
    getValue(model) {
      return model.geometry ? model.geometry[0].lowerLimit : null;
    },
    setValue(model, value) {
      model.geometry[0].lowerLimit = value;
    }
  },
  {
    id: 'upperLimit',
    title: () => {
      return i18n.global.t('labels.common.maxAltitude') + ' (' + ModelUtils.constructDisplayedUomAndCrs() + ')';
    },
    propName: 'geometry[0].upperLimit',
    isMandatory: true,
    isValid(upperLimit, model) {
      return validateAltitudeField(upperLimit, model);
    },
    getValue(model) {
      return model.geometry ? model.geometry[0].upperLimit : null;
    },
    setValue(model, value) {
      model.geometry[0].upperLimit = value;
    }
  },
  {
    id: 'timeBegin',
    title: 'labels.common.start',
    propName: 'applicability[0].startDateTime',
    isMandatory: true,
    isDate: true,
    format: 'yyyy-MM-dd HH:mm',
    isValid(timeBegin) {
      return timeBegin !== null && timeBegin !== '' &&
        TimeService.currentUtcTime().diff(TimeService.asMoment(timeBegin), 'minutes') <= 1;
    },
    getValue(model) {
      return model.applicability[0].startDateTime;
    },
    setValue(model, value) {
      model.applicability[0].startDateTime = TimeService.truncateTimestampToMinuteGranularity(value);
    }
  },
  {
    id: 'timeEnd',
    title: 'labels.geozone.end',
    propName: 'applicability[0].endDateTime',
    isMandatory: true,
    isDate: true,
    format: 'yyyy-MM-dd HH:mm',
    isValid(timeEnd, model) {
      return timeEnd !== null && timeEnd !== '' &&
        TimeService.currentUtcTime().diff(TimeService.asMoment(timeEnd), 'minutes') <= 0 &&
        timeEnd > model.applicability[0].startDateTime;
    },
    getValue(model) {
      return model.applicability[0].endDateTime;
    },
    setValue(model, value) {
      model.applicability[0].endDateTime = value;
    }
  },
]

const getFieldByPropName = (propName) => {
  return getGeozoneCreationDetailsFieldByPropName(propName);
}

const getGeozoneCreationDetailsFieldByPropName = (propName) => {
  return geozoneCreationDetailsFields.find(gdf => gdf.propName === propName);
}

const validateGeozoneCreationDetailsField = (propName, value, model) => {
  return getGeozoneCreationDetailsFieldByPropName(propName).isValid(value, model);
}

const isMinAltitudeLowerThanMaxAltitude = (model) => {
  return Number.parseInt(model.geometry[0].lowerLimit) < Number.parseInt(model.geometry[0].upperLimit);
}

const validateAltitudeField = (value, model) => {
  return value === '' ? false :
    isValidNumber(value) ? value !== null && value >= 0 && isMinAltitudeLowerThanMaxAltitude(model) : false;
}

export default {
  geozoneCreationDetailsFields,
  getFieldByPropName,
  getGeozoneCreationDetailsFieldByPropName,
  validateGeozoneCreationDetailsField,
  isMinAltitudeLowerThanMaxAltitude
}