<template>
  <div class="h-100">
    <div class="h-100" v-if="selectedElem">
      <MessageDetailedViewForm
        :all-messages="filteredMessages"
        :messageId="selectedElem"
        :model="model"
      ></MessageDetailedViewForm>
    </div>
    <div v-else>
      <div class="utmPanelListHeader">
        <div class="utmPanelListLeftSideHeader pt-4">
          <div class="counterButtonsContainer">
            <button
              :class="{
                 'messageFilterButton': true,
                 'btn-primary': true,
                 'mr-2': true,
                 'selected': stateIsSelected(counter.state)
              }"
              :key="$t(counter.text)"
              :style="counter.style()"
              @click="filterMessagesByState(counter.state)"
              type="button"
              v-for="counter in getCounters"
            >
              {{ $t(counter.text) }} {{ counter.count(countedMessages) }}
            </button>
          </div>
        </div>
        <div class="utmPanelListRightSideHeader">
          <div class="time-interval-filter-container">
            {{ $t('labels.operationPlan.showPlansBetween') }}
            <TimeIntervalFilter
              :feature="'plans'"
              :lower-bound-options="timeIntervalFilterOptions"
              :upper-bound-options="timeIntervalFilterOptions"
              v-model:time-interval-filter-value="timeIntervalFilterValue"
            />
            {{ $t('labels.common.fromNow') }}.
          </div>
          <div>
            <SmartSisCheckbox
              class="manually-created-filter-control"
              :label="$t('labels.operationPlan.showOnlyManuallyCreated')"
              v-model="showOnlyManuallyCreated"
            />
          </div>
        </div>
      </div>
      <div class="search">
        <input
          class="form-control form-control-lg form-control-borderless"
          :placeholder="$t('labels.operationPlan.searchByOperatorNameAndId')"
          type="text"
          v-model="searchText"
        >
      </div>
      <List
        :elements="filteredMessages"
        :listHeight="'100'"
        :model="model"
        :onSelect="this.onSelect"
        :onSort="this.onSort"
        :reverse="this.sortingOrder"
        :sortCriteria="this.sortingCriteria"
      ></List>
    </div>
  </div>
</template>

<script>

import A from '../../../constants/actions';
import List from './common/List';
import OperationPlanModel from '../../../model/operationPlan';
import OPCounterConfig from '../../../config/OPCounterConfig';
import FeatureType from '../../../constants/featureType';
import MessageDetailedViewForm from '../detailed-view-form-components/MessageDetailedViewForm';
import SmartSisCheckbox from "../../../common/SmartSisCheckbox";
import TimeIntervalFilter from "../filter/TimeIntervalFilter";
import FeatureUtils from "../../../utils/FeatureUtils";
import TimeService from "../../../services/timeService";
import {mapGetters} from "vuex";
import OperationPlaneState from "../../../constants/operationPlanState";
import PopupUtils from "../../../utils/PopupUtils";
import Logger from "../../../utils/LoggerUtils";
import SmartSisUserService from '../../../services/smartSisUserService';
import DialogUtils from "../../../utils/DialogUtils";
import SortingOrderConfig from "../../../config/sortingOrderConfig";
import DronePlanUtils from "../../../utils/dronePlans/DronePlanUtils";
import opTimeIntervalFilterOptions from '../../../config-files/op-time-interval-filter-options.json'

export default {
  name: 'DronePlans',
  components: {
    MessageDetailedViewForm,
    List,
    SmartSisCheckbox,
    TimeIntervalFilter
  },
  data() {
    return {
      queryText: '',
      stateQuery: [],
      showOnlyManuallyCreatedOps: false,
      timeIntervalFilterValue: {
        lowerBound: opTimeIntervalFilterOptions.lowerBoundDefaultValue,
        upperBound: opTimeIntervalFilterOptions.upperBoundDefaultValue
      }
    }
  },
  beforeMount() {
    const matchingTimeIntervalDefaultValues = this.matchingFeatureTypeTimeIntervalDefaultValuesMapping;
    if (matchingTimeIntervalDefaultValues) {
      this.timeIntervalFilterValue = matchingTimeIntervalDefaultValues;
    }
  },
  computed: {
    ...mapGetters({
      lastActiveTab: 'lastActiveInformationTab',
      outlookUpperBoundFilteringValue: 'outlookPanelSelectedFilter',
      sortingConfigForFeatureType: 'getUserSortingConfigForFeatureType',
      sortingConfigs: 'getUserSortingConfigs',
      sortingOrder: ''
    }),
    searchText: {
      get() {
        return this.queryText;
      },
      set(value) {
        this.queryText = value;
      }
    },
    showOnlyManuallyCreated: {
      get() {
        return this.showOnlyManuallyCreatedOps;
      },
      set() {
        this.showOnlyManuallyCreatedOps = this.showOnlyManuallyCreatedOps === false;
      }
    },
    model() {
      return OperationPlanModel;
    },
    messages() {
      return this.$store.state.informationStore.messages
        .filter(msg => msg.featureType.indexOf(FeatureType.OPERATION_PLAN) !== -1);
    },
    filters: {
      get() {
        return [
          {
            shouldApply: this.stateQuery.length > 0,
            apply: messages => messages.filter(m => this.filterOperationPlanByState(m, this.stateQuery)),
            applyToCountedMessages: false
          },
          {
            shouldApply: this.showOnlyManuallyCreatedOps,
            apply: messages => messages.filter(m => this.isManuallyCreatedOp(m)),
            applyToCountedMessages: true
          },
          {
            shouldApply: this.queryText.length > 0,
            apply: messages => messages.filter(
              m => this.filterOperationPlanByOperatorNameOrOperatorId(m, this.queryText.toUpperCase())
            ),
            applyToCountedMessages: true
          },
          {
            shouldApply: true,
            apply: messages => this.applyTimeIntervalFilter(messages, this.timeIntervalFilterValue),
            applyToCountedMessages: true
          }
        ];
      }
    },
    featureTypeTimeIntervalDefaultValuesMapping() {
      return {
        [FeatureType.OPERATION_PLAN_PROPOSED]: {lowerBound: -12, upperBound: 1},
        [FeatureType.OPERATION_PLAN_ACCEPTED]: {lowerBound: -12, upperBound: 1},
        [FeatureType.OPERATION_PLAN_ACTIVATED]: {lowerBound: -12, upperBound: 1},
        [FeatureType.OPERATION_PLAN_OUTLOOK]: {
          lowerBound: 1,
          upperBound: parseInt(this.outlookUpperBoundFilteringValue)
        },
      };
    },
    matchingFeatureTypeTimeIntervalDefaultValuesMapping() {
      return this.lastActiveTab ?
        this.featureTypeTimeIntervalDefaultValuesMapping[this.lastActiveTab.featureType] :
        undefined;
    },
    filteredMessages() {
      let filteredMessages = this.messages;
      const sortConfigForFeature = this.sortingConfigForFeatureType(FeatureType.OPERATION_PLAN);
      this.filters
        .filter(filter => filter.shouldApply)
        .forEach(filter => filteredMessages = filter.apply(filteredMessages));

      const index = this.model.fields.findIndex(field => field.propertyName === sortConfigForFeature.sortingBy);
      const sortMethod = this.model.fields[index].sort;
      let sortedMessages = filteredMessages.sort(sortMethod);
      return SortingOrderConfig.isDescOrder(sortConfigForFeature.sortingOrder) ? sortedMessages.reverse() : sortedMessages;
    },
    countedMessages() {
      let countedMessages = this.messages;
      this.filters
        .filter(filter => filter.shouldApply && filter.applyToCountedMessages)
        .forEach(filter => countedMessages = filter.apply(countedMessages));
      return countedMessages;
    },
    selectedElem() {
      return this.$store.state.utmStore.selectedId;
    },
    getCounters() {
      return OPCounterConfig.fields.filter(conf => conf.show);
    },
    timeIntervalFilterOptions() {
      return opTimeIntervalFilterOptions.all;
    },
    sortingCriteria() {
      return this.sortingConfigForFeatureType(FeatureType.OPERATION_PLAN).sortingBy;
    },
    sortingOrder() {
      return SortingOrderConfig.isDescOrder(this.sortingConfigForFeatureType(FeatureType.OPERATION_PLAN).sortingOrder);
    }
  },
  methods: {
    onSelect(element) {
      this.$store.dispatch(A.UTM_PANEL_SELECT_ELEM, element.id);
    },
    onSort(propertyName) {
      this.$store.dispatch(A.USER_MANAGEMENT_SORTING_CONFIG_UPDATE,
        {featureType: FeatureType.OPERATION_PLAN, property: propertyName});

      const config = {
        sortingConfig: this.sortingConfigs,
        successCallback: () => {
          PopupUtils.success(this.$i18n.t('popupMessages.sortingOrderSaved'));
          Logger.info(this.$i18n.t('logMessages.sortingConfigSaved'));
        },
        errorCallback: (error) => {
          Logger.error(this.$i18n.t('errorMessages.sortingConfigSaveError', {error}));
          DialogUtils.errorNotification(error);
        },
      };

      SmartSisUserService.saveUserSortingConfig(config);
    },
    filterMessagesByState(verifiedState) {
      if (verifiedState === 'ALL') {
        this.stateQuery = [];
      } else {
        if (this.stateIsSelected(verifiedState)) {
          this.stateQuery = this.stateQuery.filter(state => state !== verifiedState);
        } else {
          this.stateQuery.push(verifiedState);
        }
      }
    },
    filterOperationPlanByOperatorNameOrOperatorId(op, text) {
      let contactName = DronePlanUtils.constructOperatorName(op);
      return contactName.toUpperCase().indexOf(text) !== -1 || op.operator.toUpperCase().indexOf(text) !== -1;
    },
    filterOperationPlanByState(op, filteredStates) {
      return filteredStates.some(state => op.state === state);
    },
    isManuallyCreatedOp(op) {
      return FeatureUtils.Icons.isManuallyCreatedOperationPlan(op);
    },
    stateIsSelected(verifiedState) {
      return verifiedState === 'ALL' ? this.stateQuery.length === 0 :
        this.stateQuery.some(state => verifiedState === state);
    },
    applyTimeIntervalFilter(operationPlans) {
      const lowerBoundTimestamp = this.getTimestampFromTimeIntervalBound(this.timeIntervalFilterValue.lowerBound);
      const upperBoundTimestamp = this.getTimestampFromTimeIntervalBound(this.timeIntervalFilterValue.upperBound);
      return operationPlans.filter(operationPlan =>
        this.operationMatchesSelectedTimeInterval(operationPlan, lowerBoundTimestamp, upperBoundTimestamp)
      );
    },
    getTimestampFromTimeIntervalBound(intervalBoundValue) {
      return TimeService
        .browserTime()
        .add(intervalBoundValue, 'hours');
    },
    operationMatchesSelectedTimeInterval(operationPlan, lowerBoundTimestamp, upperBoundTimestamp) {
      const effectiveTimeBeginTimestamp = TimeService.asMoment(operationPlan.validTime.from);
      const effectiveTimeEndTimestamp = TimeService.asMoment(operationPlan.validTime.to);
      const operationStartingWithinTheSelectedTimeInterval =
        effectiveTimeBeginTimestamp.isSameOrAfter(lowerBoundTimestamp) &&
        effectiveTimeBeginTimestamp.isSameOrBefore(upperBoundTimestamp);
      const operationEndedWithinTheSelectedTimeInterval =
        effectiveTimeEndTimestamp.isSameOrAfter(lowerBoundTimestamp) &&
        effectiveTimeEndTimestamp.isSameOrBefore(upperBoundTimestamp);
      const isExpiredActiveOperationPlan = operationPlan.state === OperationPlaneState.ACTIVATED.state &&
        effectiveTimeEndTimestamp.isBefore(TimeService.browserTime());
      const opValidityCoverTimeInterval =
        effectiveTimeBeginTimestamp.isSameOrBefore(lowerBoundTimestamp) &&
        effectiveTimeEndTimestamp.isSameOrAfter(upperBoundTimestamp);
      return operationStartingWithinTheSelectedTimeInterval || operationEndedWithinTheSelectedTimeInterval ||
        isExpiredActiveOperationPlan || opValidityCoverTimeInterval;
    },
  }
}
</script>
<style src="./tabs.css"></style>