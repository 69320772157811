import A from '../../constants/actions';
import ADSBFeature from '../../config/features/adsb';
import FeatureType from '../../constants/featureType';
import DialogUtils from '../../utils/DialogUtils';
import MessageProcessorService from '../../services/messageProcessorService';
import PopupUtils from '../../utils/PopupUtils';
import Store from '../../store';
import WSMessageType from '../../constants/wsMessageType';
import MapUtils from '../../utils/MapUtils';
import {transform} from 'ol/proj';
import TimeService from "../../services/timeService";
import CoordinateUtils from "../../utils/CoordinateUtils";
import DronesMessageProcessor from "./DroneMessageProcessor";
import GeoserverLayersService from "../../services/geoserverLayersService";
import AircraftMessageProcessor from "./AircraftMessageProcessor";
import AppConfig from '../../config/appConfig';
import {i18n} from '../../internationalization/index';

export default class WSMessageProcessor {

  static adsbLayerSource = null;

  static [WSMessageType.DELETE](payload) {
    const deletedMessage = JSON.parse(payload);
    const deletedId = (deletedMessage.timesliceId) ? deletedMessage.timesliceId : deletedMessage.featureId;
    const messages = Store.state.informationStore.messages;
    const toRemove = messages.filter(
      msg => msg.id === deletedId && msg.featureType.startsWith(deletedMessage.featureType)
    );

    if (deletedMessage.featureType.indexOf('operationplan') !== -1) {
      toRemove.push(
        ...messages.filter(msg => msg.featureType === FeatureType.DRONE_ALERT && deletedId === msg.operationPlanIds[0])
      );
    }
    Store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, toRemove);
  }

  static [WSMessageType.ERROR](payload) {
    const errorMessage = JSON.parse(payload);
    DialogUtils.errorNotification(errorMessage.message);
  }

  static [WSMessageType.HEARTBEAT](payload) {
    const parts = payload.split('|');
    const clientServerOffset = parts[0] - TimeService.browserTimeInMilliseconds();
    Store.dispatch(A.WEBSOCKET_REMOVE_HEARTBEAT_FROM_CACHE, parts[1]);
    Store.dispatch(A.WEBSOCKET_SET_CLIENT_SERVER_OFFSET, clientServerOffset).then(() => {
      if (!TimeService.isInHistoryMode()) {
        Store.dispatch(A.TIME_SLIDER_RESET_DATE, TimeService.currentUtcTime()).then(() => {
        });
      }
    });
  }

  static [WSMessageType.INVALID_TOKEN]() {
    Store.dispatch(A.WEBSOCKET_INVALID_TOKEN);
  }

  static [WSMessageType.ERROR_POPUP](payload) {
    PopupUtils.error(payload);
  }

  static [WSMessageType.WARNING_POPUP](payload) {
    DialogUtils.warningNotification(JSON.parse(payload).message);
  }

  static [WSMessageType.PRODUCT](payload) {
    MessageProcessorService.processFeatures(JSON.parse(payload), {src: 'push'});
  }

  static [WSMessageType.UPDATE](payload) {
    Store.dispatch(A.UPDATE_NEW_UPDATE_AVAILABLE, JSON.parse(payload));
  }

  static [WSMessageType.CLEAN_FEATURES](payload) {
    const messagesToRemove = Store.state.informationStore.messages.filter(msg => msg.featureType === payload);
    Store.dispatch(A.INFORMATION_PANEL_MESSAGE_CLEANUP, messagesToRemove);
  }

  static [WSMessageType.HEALTH_CHECK](componentsHealth) {
    Store.dispatch(A.UPDATE_HEALTH_CHECK, JSON.parse(componentsHealth));
  }

  static [WSMessageType.LOADING_COMPLETE]() {
    Store.dispatch(A.NOTIFICATION_STATUS_TOGGLE, true);
    MessageProcessorService.generateNilMessages();
    Store.dispatch(A.ENABLE_PARTIAL_SUBSCRIBE);
    Store.dispatch(A.VIEW_SET_RESETTING_VIEWPORT_DONE);
  }

  static [WSMessageType.WSQUERY](payload) {
    if (payload.startsWith(WSMessageType.QUERY_ERROR)) {
      const errorPayload = payload.split('|');
      WSMessageProcessor[WSMessageType.QUERY_ERROR](errorPayload[1]);
      return;
    }
    const features = MessageProcessorService.processESResponse(JSON.parse(payload));
    MessageProcessorService.processFeatures(features, {src: 'push'});
  }

  static [WSMessageType.QUERY_ERROR](payload) {
    const error = JSON.parse(payload);
    const errorMessage = error.code !== undefined ? i18n.global.t('serviceErrorMessages.' + error.code)
      : error.error || error.message;
    PopupUtils.error(errorMessage);
  }

  static [WSMessageType.DOCUMENTS](payload) {
    const documents = JSON.parse(payload);
    Store.dispatch(A.DOCUMENTS_PANEL_UPDATE_DOCUMENTS, documents);
    Store.dispatch(A.DOCUMENTS_UPDATE_IS_AVAILABLE);
    PopupUtils.warning(i18n.global.t('popupMessages.documentsListUpdated'));
  }

  static [WSMessageType.ADSB](payload) {
    if (this.adsbLayerSource === null) {
      this.adsbLayerSource = MapUtils.getMapLayerSourceById(FeatureType.ADSB);
    }
    if (TimeService.timeOffsetInMilliseconds() === 0) {
      const records = JSON.parse(payload);
      this.processFlyingObjectFeature(
        records,
        this.adsbLayerSource,
        ADSBFeature,
        this.updateExistingAirplaneProperties
      );
    }
  }

  static [WSMessageType.DRONES](payload) {
    DronesMessageProcessor.process(payload);
  }

  static [WSMessageType.AIRCRAFT](payload) {
    AircraftMessageProcessor.process(payload);
  }

  static [WSMessageType.REFRESH_GEOSERVER_LAYER](payload) {
    GeoserverLayersService.updateGeoserverLayerByLayerId(payload);
  }

  static updateExistingAirplaneProperties(feature, record) {
    const start = feature.getGeometry().getCoordinates();
    const end = transform(record.p, 'EPSG:4326', 'EPSG:3857');
    feature.getGeometry().setCoordinates(end);
    feature.setProperties(
      {
        validTime: {
          to: TimeService.currentTimeSliderTime().valueOf() + AppConfig.adsbValidity * 1000,
        },
        trackAngle: CoordinateUtils.computeAngle(start, end),
        altitude: record.a,
        timestamp: record.timestamp
      },
      true
    );
  }

  static processFlyingObjectFeature(records, layerSource, featureConfig, updateFeatureProperties) {
    records.forEach(record => {
      const feature = layerSource.getFeatureById(record.id);
      if (feature) {
        if (Date.parse(feature.getProperties().timestamp) <= Date.parse(record.timestamp)) {
          updateFeatureProperties(feature, record);
        }
      } else {
        Store.dispatch(A.MAP_ADD_MESSAGE, featureConfig.geoJsonMapper(record));
      }
    });
  }
}