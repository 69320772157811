import A from '../../constants/actions';
import M from '../../constants/mutations';
import Features from '../../config/features';
import Store from '../';
import draggableConfig from '../../config/draggableConfig';
import FeatureType from '../../constants/featureType';
import FeatureUtils from '../../utils/FeatureUtils'
import TimeService from '../../services/timeService';
import BaseFeaturesConfig from "../../config/BaseFeaturesConfig";
import DronesMessageProcessor from "../../components/websocket/DroneMessageProcessor";
import GeozoneFilterType from '../../constants/geozoneFilterType';
import HudTabName from '../../constants/hudTabName';
import TaskPanelSubTabsConfig from "../../config/TaskPanelSubTabsConfig";
import {reactive} from "vue";

function removeSearchAddedStaticData(messagesToDelete, dispatch) {
  let viewGetters = Store.getters;
  let dataToRemove = messagesToDelete.map(msg => msg.station);
  dataToRemove = dataToRemove.filter(staticData => (viewGetters.getCurrentAirports.indexOf(staticData) === -1 && viewGetters.getCurrentFirs.indexOf(staticData) === -1));
  const usedStations = Store.state.informationStore.messages.map(m => m.station);
  dataToRemove = dataToRemove.filter(sd => usedStations.indexOf(sd) !== -1 && usedStations.indexOf(sd) === usedStations.lastIndexOf(sd));
  dispatch(M.MAP_MESSAGE_CLEANUP,
    [
      ...state.staticData.airspace.filter(fir => dataToRemove.indexOf(fir.station) > -1),
      ...state.staticData.airportheliport.filter(ap => dataToRemove.indexOf(ap.station) > -1),
    ]);
}

function checkIfFeatureExists(state, assocFeature) {
  const associatedFeatureList = state.associatedFeaturesCache.get(assocFeature.operationPlanGufi);
  return associatedFeatureList.filter(feat => feat.id === assocFeature.id && feat.featureType === assocFeature.featureType).length !== 0;
}

const state = {
  width: draggableConfig.leftPanel.minSize,
  dragging: false,
  datePickerIsOpen: false,
  activeTab: null,
  lastActiveTab: null,
  selectedTopics: [],
  staticData: {
    airportheliport: [],
    airspace: [],
    designatedpoint: [],
    runwayelement: [],
    taxiwayelement: [],
    apronelement: [],
    routesegment: [],
    responsibilityarea: [],
    runwaydirection: [],
  },
  messages: reactive([]),
  associatedFeaturesCache: new Map(),
  favorites: [],
  selectedFeatureId: null,
  lastRenderTime: null,
  forceRenderCount: 0,
  droneIdToLink: null,
  droneIdsForWhichInfoIsBeingFetched: [],
  taskTabOpened: false,
  taskTabSelectedSubTab: TaskPanelSubTabsConfig.TASK_PANEL_SUB_TABS.APPROVAL_REQUESTS,
  geozoneActiveFilter: GeozoneFilterType.DYNAMIC,
};

const actions = {
  [A.CHANGE_LAST_RENDER_TIME]({commit}, value) {
    commit(M.CHANGE_LAST_RENDER_TIME, value);
  },

  [A.DIRECTORY_SELECT_TOPIC]({commit}, value) {
    commit(M.DIRECTORY_SELECT_TOPIC, value);
  },

  [A.DIRECTORY_ADD_TOPIC]({commit}, value) {
    commit(M.DIRECTORY_ADD_TOPIC, value);
  },

  [A.LEFT_PANEL_WIDTH_CHANGE]({commit}, value) {
    commit(M.LEFT_PANEL_WIDTH_CHANGE, value);
  },

  [A.INFORMATION_PANEL_SELECT_TAB]({commit, state, dispatch}, tab) {
    commit(M.INFORMATION_PANEL_SELECT_TAB, tab);
    if (Store.getters.isGivenTabOpened(HudTabName.CONFIGURATION) && tab) {
      dispatch(A.CONFIGURATION_PANEL_TOGGLE, HudTabName.CONFIGURATION);
    }
    if (Store.getters.isGivenTabOpened(HudTabName.UTM_PANEL) && tab) {
      dispatch(A.UTM_PANEL_TOGGLE);
    }
    if (state.taskTabOpened && tab) {
      commit(M.INFORMATION_PANEL_TOGGLE_TASK_TAB);
    }
    dispatch(A.MAP_REFRESH);
  },

  [A.VIEW_SELECT_VIEW]({commit, dispatch}) {
    commit(M.INFORMATION_PANEL_SELECT_TAB, null);
    dispatch(A.MAP_REFRESH);
  },

  [A.INFORMATION_PANEL_MSG_RECEIVED]({commit, state, dispatch}, message) {
    const featureConfig = Features.getFeature(message.featureType);
    const toRemove = (featureConfig && featureConfig.getReplaceableMessages) ? featureConfig.getReplaceableMessages(message, state.messages) : [];
    const favorite = toRemove.find(msg => FeatureUtils.Filters.isMessageMarkedAsFavorite(msg));

    dispatch(A.MAP_MESSAGE_CLEANUP, toRemove);
    commit(M.INFORMATION_PANEL_MESSAGE_CLEANUP, toRemove);

    commit(M.INFORMATION_PANEL_ADD_MSG, message);
    if (favorite && !FeatureUtils.Filters.isMessageMarkedAsFavorite(message)) {
      commit(M.INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE, message);
    }

    if (message.src === 'push' && Store.state.notificationStore.enabled && !TimeService.isInHistoryMode()) {
      dispatch(A.NOTIFICATION_NEW_MESSAGE, message);
    }
    if (featureConfig?.handleSideEffects) {
      featureConfig.handleSideEffects(message);
    }
  },

  [A.INFORMATION_PANEL_TOGGLE_FILTER]({commit}, filterId) {
    commit(M.INFORMATION_PANEL_TOGGLE_FILTER, filterId);
  },

  [A.INFORMATION_PANEL_TOGGLE_DATE_PICKER]({commit}) {
    commit(M.INFORMATION_PANEL_TOGGLE_DATE_PICKER);
  },

  [A.INFORMATION_PANEL_TOGGLE]({commit, dispatch}, open) {
    commit(M.INFORMATION_PANEL_TOGGLE, open);
    if (Store.getters.isGivenTabOpened(HudTabName.CONFIGURATION) && open) {
      dispatch(A.CONFIGURATION_PANEL_TOGGLE, HudTabName.CONFIGURATION);
    }
  },

  [A.INFORMATION_PANEL_MESSAGE_SEEN]({commit}, message) {
    commit(M.INFORMATION_PANEL_MESSAGE_SEEN, message);
  },

  [A.INFORMATION_PANEL_MESSAGE_CLEANUP]({commit, state, dispatch}, messagesToDelete) {
    let childMessages = [];
    let staticDataToRemove = [];
    messagesToDelete.forEach((msg) => {
      childMessages = [
        ...childMessages,
        ...state.messages.filter(message => message.groupId === msg.id),
      ];
      staticDataToRemove = [
        ...staticDataToRemove,
        ...[].concat.apply([], Object.keys(state.staticData).map(sd => state.staticData[sd].filter(data => data.groupId === msg.id))),
      ]
    });
    removeSearchAddedStaticData(messagesToDelete, dispatch);
    commit(M.INFORMATION_PANEL_MESSAGE_CLEANUP, [...messagesToDelete, ...childMessages]);
    dispatch(A.MAP_MESSAGE_CLEANUP, [...messagesToDelete, ...childMessages]);
    dispatch(A.MAP_MESSAGE_CLEANUP, staticDataToRemove);
  },

  [A.INFORMATION_PANEL_MESSAGE_UPDATED]({commit}, updatedMessage, identifierFieldName) {
    commit(M.INFORMATION_PANEL_MESSAGE_UPDATED, updatedMessage, identifierFieldName);
  },

  [A.INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE]({commit}, message) {
    commit(M.INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE, message);
  },

  [A.INFORMATION_WIPE]({commit}) {
    commit(M.INFORMATION_WIPE);
    DronesMessageProcessor.wipeAllCachedDrones();
  },

  [A.INFORMATION_PANEL_SCROLL_TO_MESSAGE]({commit}, messageId) {
    commit(M.INFORMATION_PANEL_SCROLL_TO_MESSAGE, messageId);
  },

  [A.SELECT_FEATURE]({commit}, feature) {
    commit(M.SELECT_FEATURE, feature);
    if (feature.featureType === FeatureType.ED269) {
      const focusedGeozoneFilterType = feature.isCreatedBySmartsis ?
        GeozoneFilterType.DYNAMIC : GeozoneFilterType.STATIC;
      commit(M.INFORMATION_PANEL_TOGGLE_GEOZONE_FILTER, focusedGeozoneFilterType);
    }
  },

  [A.REMOVE_SELECT_FEATURE]({commit}) {
    commit(M.REMOVE_SELECT_FEATURE);
    commit(M.MAP_DESELECT_ALL_FEATURES);
  },

  [A.STATIC_DATA_RECEIVED]({commit}, minifiedObject) {
    commit(M.STATIC_DATA_RECEIVED, minifiedObject);
  },

  [A.UPDATE_CURRENT_RESPONSIBILITY_AREA_FOR_CONFIG]({commit}, responsibilityArea) {
    commit(M.UPDATE_CURRENT_RESPONSIBILITY_AREA_FOR_CONFIG, responsibilityArea);
  },

  [A.ASSOC_FEATURE_ADD_TO_CACHE]({commit}, assocFeature) {
    commit(M.ASSOC_FEATURE_ADD_TO_CACHE, assocFeature);
  },

  [A.ASSOC_FEATURE_REM_FROM_CACHE]({commit}, gufi) {
    commit(M.ASSOC_FEATURE_REM_FROM_CACHE, gufi);
  },

  [A.DRONE_ADD_DRONE_INFO]({commit}, config) {
    commit(M.DRONE_ADD_DRONE_INFO, config);
  },

  [A.DRONE_TOGGLE_FETCHING_INFO]({commit}, droneTrackingId) {
    commit(M.DRONE_TOGGLE_FETCHING_INFO, droneTrackingId);
  },

  [A.DRONE_ID_TO_LINK_SET]({commit}, droneId) {
    commit(M.DRONE_ID_TO_LINK_SET, droneId);
  },

  [A.INFORMATION_PANEL_TOGGLE_TASK_TAB]({commit, dispatch}) {
    dispatch(A.REMOVE_SELECT_FEATURE);
    dispatch(A.HUD_SET_OPENED_TAB, null);
    commit(M.INFORMATION_PANEL_TOGGLE_TASK_TAB);
    commit(M.INFORMATION_PANEL_SELECT_TAB, null);
    commit(M.MAP_REFRESH);
  },

  [A.INFORMATION_PANEL_CLOSE_TASK_TAB]({commit}) {
    commit(M.INFORMATION_PANEL_CLOSE_TASK_TAB);
  },

  [A.INFORMATION_PANEL_UPDATE_SELECTED_TASK_SUB_TAB]({commit}, selectedTaskSubTab) {
    commit(M.INFORMATION_PANEL_UPDATE_SELECTED_TASK_SUB_TAB, selectedTaskSubTab);
  },

  [A.INFORMATION_PANEL_TOGGLE_GEOZONE_FILTER]({commit}, filter) {
    commit(M.INFORMATION_PANEL_TOGGLE_GEOZONE_FILTER, filter);
  },
};

const mutations = {
  [M.CHANGE_LAST_RENDER_TIME](state, value) {
    state.lastRenderTime = value;
  },

  [M.DIRECTORY_SELECT_TOPIC](state, value) {
    const index = state.selectedTopics.indexOf(value);
    if (index !== -1) {
      state.selectedTopics.splice(index, 1);
    } else {
      state.selectedTopics.push(value);
    }
  },

  [M.DIRECTORY_ADD_TOPIC](state, value) {
    const index = state.selectedTopics.indexOf(value);
    if (index === -1) {
      state.selectedTopics.push(value);
    }
  },

  [M.LEFT_PANEL_WIDTH_CHANGE](state, value) {
    state.width = value;
  },

  [M.INFORMATION_PANEL_SELECT_TAB](state, tab) {
    state.lastActiveTab = state.activeTab;
    state.activeTab = (!tab || (state.activeTab && state.activeTab.id === tab.id)) ? null : tab;
  },

  [M.INFORMATION_PANEL_ADD_MSG](state, msg) {
    const timeWithOffset = TimeService.currentTimeSliderTime().valueOf();
    const visualNotification = Store.state.viewStore.currentView.notifications.find(notificationType => notificationType.type === 'visual');

    msg.validTime = msg.validTime || {};
    msg.validTime.to = msg.validTime.to || Date.now() + 60 * 60 * 1000;

    const displayNotification = visualNotification.products.includes(msg.featureType) &&
      (msg.src === 'push' || msg.src === 'timeout');

    if (msg.refrainFromInfoPanel) {
      msg.seen = true;
    } else {
      msg.seen = !displayNotification;
    }
    msg.addedOn = timeWithOffset;
    state.messages.push(msg);
  },

  [M.INFORMATION_PANEL_MESSAGE_SEEN](state, message) {
    const index = state.messages.indexOf(message);
    if (state.messages[index]) {
      state.messages[index].seen = true;
      state.messages[index].elements?.forEach(msg => msg.seen = true);
      state.messages.splice(state.messages.length);
    }
  },

  [M.INFORMATION_PANEL_MESSAGE_CLEANUP](state, messagesToDelete) {
    messagesToDelete = messagesToDelete || [];
    if (messagesToDelete.length === 0) return;

    state.messages = state.messages.filter((message) => {
      return !messagesToDelete.some(messageToDelete => messageToDelete.id === message.id);
    });

  },

  [M.INFORMATION_PANEL_MESSAGE_UPDATED](state, [updatedMessage, identifierFieldName]) {
    let messageToUpdate =
      state.messages
        .find(message => message[identifierFieldName || 'id'] === updatedMessage[identifierFieldName || 'id']);
    Object.assign(messageToUpdate, updatedMessage);
  },

  [M.INFORMATION_PANEL_TOGGLE_FAVORITE_MESSAGE](state, message) {
    const isMessageMarkedAsFavorite = FeatureUtils.Filters.isMessageMarkedAsFavorite(message)
    if (isMessageMarkedAsFavorite) {
      state.favorites = state.favorites.filter(favorite => favorite.id !== message.id);
    } else {
      state.favorites.push({id: message.id, validTime: message.validTime});
    }
  },

  [M.INFORMATION_PANEL_TOGGLE_DATE_PICKER](state) {
    state.datePickerIsOpen = !state.datePickerIsOpen;
  },

  [M.INFORMATION_PANEL_SCROLL_TO_MESSAGE](state, messageId) {
    setTimeout(() => {
      const messageDOMObj = document.getElementById(messageId);
      if (messageDOMObj) {
        messageDOMObj.scrollIntoView();
      }
    }, 500);
  },

  [M.INFORMATION_WIPE](state) {
    state.staticData.airspace.splice(0, state.staticData.airspace.length);
    state.staticData.airportheliport.splice(0, state.staticData.airportheliport.length);
    state.messages.splice(0, state.messages.length);
  },

  [M.SELECT_FEATURE](state, feature) {
    const f = BaseFeaturesConfig.belongsToABaseFeature(feature.featureType) ?
      BaseFeaturesConfig.getBaseFeatureFor(feature.featureType) : Features.getFeature(feature.featureType);
    const message = state.messages.find(m => m.id === feature.id);
    const messageTaskPanelSubTabId = TaskPanelSubTabsConfig.getTaskPanelSubTabIdForMessage(message);
    const isFeatureDisplayedInTasksPanelAndTasksPanelIsOpen =
      state.taskTabOpened && messageTaskPanelSubTabId !== undefined;
    if (message && f.displayInMessages && !isFeatureDisplayedInTasksPanelAndTasksPanelIsOpen) {
      state.activeTab = FeatureUtils.Mapping.getTabs(f).filter(t => t.filter(message))[0];
      state.activeTab.featureType = feature.associatedTab || feature.featureType;
      state.activeTab.originalFeatureType = feature.featureType;
      state.taskTabOpened = messageTaskPanelSubTabId !== undefined ? state.taskTabOpened : false;
    }
    if (isFeatureDisplayedInTasksPanelAndTasksPanelIsOpen) {
      state.taskTabSelectedSubTab = messageTaskPanelSubTabId;
    }
    state.selectedFeatureId = feature.parentId || feature.id;
    state.messages.splice(0, 0);
  },

  [M.REMOVE_SELECT_FEATURE](state) {
    if (state.selectedFeatureId) {
      state.selectedFeatureId = null;
      state.messages.splice(0, 0);
    }
  },

  [M.STATIC_DATA_RECEIVED](state, minifiedObject) {
    const staticDataType = minifiedObject.featureType;
    const index = state.staticData[staticDataType].findIndex(ap => ap.id === minifiedObject.id);
    if (index > -1) {
      state.staticData[staticDataType].splice(index, 1, minifiedObject);
    } else {
      state.staticData[staticDataType].push(minifiedObject);
    }
  },

  [M.UPDATE_CURRENT_RESPONSIBILITY_AREA_FOR_CONFIG](state, responsibilityArea) {
    const index = state.staticData.responsibilityarea
      .findIndex(respArea => respArea.designator === responsibilityArea.designator);
    if (index > -1) {
      state.staticData.responsibilityarea.splice(index, 1, responsibilityArea);
    }
  },

  [M.ASSOC_FEATURE_ADD_TO_CACHE](state, assocFeature) {
    if (state.associatedFeaturesCache.has(assocFeature.operationPlanGufi)) {
      if (!checkIfFeatureExists(state, assocFeature)) {
        state.associatedFeaturesCache.get(assocFeature.operationPlanGufi).push(assocFeature);
      }
    } else {
      state.associatedFeaturesCache.set(assocFeature.operationPlanGufi, [assocFeature]);
    }
  },

  [M.ASSOC_FEATURE_REM_FROM_CACHE](state, gufi) {
    if (state.associatedFeaturesCache.has(gufi)) {
      state.associatedFeaturesCache.delete(gufi);
    }
  },

  [M.DRONE_ADD_DRONE_INFO](state, config) {
    let drones = state.messages.filter(msg => msg.featureType === FeatureType.DRONES && msg.registration === config.droneId);
    if (drones.length > 0) {
      let drone = drones[0];
      let index = state.messages.findIndex(msg => msg.id === drone.id);
      state.messages.splice(index, 1);
      drone.droneInfo = config.droneInfo;
      state.messages.push(drone);
    }
  },

  [M.DRONE_TOGGLE_FETCHING_INFO](state, droneTrackingId) {
    if (state.droneIdsForWhichInfoIsBeingFetched.includes(droneTrackingId)) {
      const indexToRemove = state.droneIdsForWhichInfoIsBeingFetched.indexOf(droneTrackingId);
      state.droneIdsForWhichInfoIsBeingFetched.splice(indexToRemove, 1);
    } else {
      state.droneIdsForWhichInfoIsBeingFetched.push(droneTrackingId);
    }
  },

  [M.DRONE_ID_TO_LINK_SET](state, droneId) {
    state.droneIdToLink = droneId;
  },

  [M.INFORMATION_PANEL_TOGGLE_TASK_TAB](state) {
    state.taskTabOpened = !state.taskTabOpened;
    state.taskTabSelectedSubTab = TaskPanelSubTabsConfig.TASK_PANEL_SUB_TABS.APPROVAL_REQUESTS;
  },

  [M.INFORMATION_PANEL_CLOSE_TASK_TAB](state) {
    state.taskTabOpened = false;
  },

  [M.INFORMATION_PANEL_UPDATE_SELECTED_TASK_SUB_TAB](state, selectedTaskSubTab) {
    state.taskTabSelectedSubTab = selectedTaskSubTab;
  },

  [M.INFORMATION_PANEL_TOGGLE_GEOZONE_FILTER](state, filter) {
    state.geozoneActiveFilter = filter;
  }
};

const getters = {
  activeInformationTab: (state) => {
    return state.activeTab;
  },
  lastActiveInformationTab: (state) => {
    return state.lastActiveTab;
  },
  getMessages: (state) => {
    return state.messages;
  },
  getMessageById: (state) => (id) => {
    return state.messages.find(m => m.id === id);
  },

  getAirports: (state) => {
    return state.staticData.airports;
  },

  getFirs: (state) => {
    return state.staticData.firs;
  },

  getResponsibilityAreaByDesignator: (state) => (designator) => {
    return state.staticData.responsibilityarea.find(ra => ra.designator === designator);
  },

  getCurrentResponsibilityAreasForConfig: state => {
    return state.staticData.responsibilityarea.sort((a, b) => a.designator > b.designator ? 1 : -1);
  },

  getOperationPlanByOpGufi: (state) => (opGufi) => {
    return state.messages
      .filter(message => message.featureType.startsWith(FeatureType.OPERATION_PLAN))
      .filter(op => op.id === opGufi);
  },

  getOperationPlanById: (state) => (id) => {
    return state.messages
      .filter(message => message.featureType.startsWith(FeatureType.OPERATION_PLAN))
      .find(op => op.id === id);
  },

  getOperationPlans: state => {
    return state.messages
      .filter(message => message.featureType.startsWith(FeatureType.OPERATION_PLAN));
  },

  getDroneByFlightId: (state) => (flightId) => {
    return state.messages
      .filter(m => m.featureType === FeatureType.DRONES)
      .find(drone => drone.flightId === flightId);
  },

  getMessageByIdAndFeatureType: (state) => (messageId, featureType) => {
    return featureType ? state.messages
      .filter(message => message.id === messageId && message.featureType.startsWith(featureType)) : [];
  },

  getAssociatedFeaturesByGufi: (state) => (gufi) => {
    return state.associatedFeaturesCache.get(gufi);
  },

  getDroneIdToLink: state => {
    return state.droneIdToLink;
  },

  isFetchingDroneInfo: state => droneTrackingId => {
    return state.droneIdsForWhichInfoIsBeingFetched.includes(droneTrackingId);
  },

  taskTabIsOpened: state => {
    return state.taskTabOpened;
  },

  geozoneActiveFilter: state => {
    return state.geozoneActiveFilter;
  },

  selectedFeatureId: state => {
    return state.selectedFeatureId;
  },

  selectedTaskSubTab: state => state.taskTabSelectedSubTab

};

export default {
  state,
  actions,
  getters,
  mutations,
};
