import A from '../../constants/actions';
import M from '../../constants/mutations';
import BackgroundConfig from '../../config/backgroundConfig';
import Features from '../../config/features';
import StyleFunctionService from '../../styleFunction/styleFunctionService';
import {fromLonLat} from 'ol/proj';
import ProjectionConverter from '../../mappers/ProjectionConverter';
import AppConfig from '../../config/appConfig';
import LayerUtils from "../../utils/LayerUtils";
import Feature from "ol/Feature";
import {fromExtent} from "ol/geom/Polygon";
import Store from '..';
import DataFormat from '../../constants/dataFormat';
import MapUtils from "../../utils/MapUtils";
import {i18n} from '../../internationalization/index';
import OpGeometryUtils from "../../utils/dronePlans/OpGeometryUtils";
import Point from "ol/geom/Point";

const state = {
  map: null,
  snapshot: null,
  is3dModeEnabled: false,
  interactionHandler: null,
  editMessageText: '',
  enablePartialSubscribe: true,
  showOverlayEditMessage: false,
  clickedMessages: [],
};

const actions = {

  [A.INIT_MAP_HANDLER]({commit}, map) {
    commit(M.INIT_MAP_HANDLER, map);
  },

  [A.UPDATE_BASE_MAP]({commit}, backgroundId) {
    const basemapLayers = [...BackgroundConfig.backgrounds, ...Store.getters.getBaseMapLayers];
    const basemap = basemapLayers.find((basemap) => basemap.id === backgroundId);
    commit(M.UPDATE_BASE_MAP, basemap ? basemap : basemapLayers[0]);
  },

  [A.MAP_CHANGE_LAYER_VISIBILITY]({commit}, layerInfo) {
    commit(M.MAP_CHANGE_LAYER_VISIBILITY, layerInfo);
  },

  [A.FLY_TO_FEATURE]({commit}, params) {
    commit(M.FLY_TO_FEATURE, params);
  },

  [A.ZOOM_TO_VIEWPORT]({commit}, viewPort) {
    commit(M.ZOOM_TO_VIEWPORT, viewPort);
  },

  [A.ZOOM_TO_EXTENT]({commit}, extent) {
    commit(M.ZOOM_TO_EXTENT, extent);
  },

  [A.MAP_ADD_MESSAGE]({commit}, message) {
    commit(M.MAP_ADD_MESSAGE, message);
  },

  [A.MAP_ADD_GEOSERVER_LAYERS]({commit}) {
    commit(M.MAP_REMOVE_ALL_LAYERS, Store.getters.getGeoserverLayerIds)
    commit(M.MAP_ADD_GEOSERVER_LAYERS);
  },

  [A.MAP_REFRESH_GEOSERVER_LAYERS]({commit}) {
    commit(M.MAP_REMOVE_ALL_LAYERS, Store.getters.getGeoserverLayerIds)
    commit(M.MAP_ADD_GEOSERVER_LAYERS);
  },

  [A.MAP_REFRESH_ALL_LAYERS]({state}) {
    state.map.getLayers().forEach(layer => layer.changed());
  },

  [A.MAP_REFRESH_LAYERS_BY_FEATURE_TYPE]({state}, featureType) {
    state.map.getLayers().getArray().find(layer => layer.get('id') === featureType)?.changed();
  },

  [A.MAP_REFRESH_LAYER_DATA_BY_ID]({commit}, layerId) {
    commit(M.MAP_REFRESH_LAYER_DATA_BY_ID, layerId);
  },

  [A.ENABLE_3D]({commit}, enabled) {
    commit(M.ENABLE_3D, enabled);
  },

  [A.MAP_REMOVE_FEATURES]({commit}, layerIds) {
    commit(M.MAP_REMOVE_FEATURES, layerIds);
  },

  [A.THEME_UPDATE_LAYER_STYLING]({commit}, stylingConfig) {
    commit(M.REFRESH_LAYER, stylingConfig);
  },

  [A.GLOBAL_CONFIGURATION_EDIT_GEOZONE_CONFIG]({commit}, config) {
    commit(M.REFRESH_LAYER, config.style);
  },

  [A.MAP_MESSAGE_CLEANUP]({commit}, messagesToRemove) {
    commit(M.MAP_MESSAGE_CLEANUP, messagesToRemove);
  },

  [A.MAP_VIEWPORT_SNAPSHOT]({commit}, snapshot) {
    commit(M.MAP_VIEWPORT_SNAPSHOT, snapshot);
  },

  [A.MAP_WIPE]({commit}) {
    commit(M.MAP_WIPE, Features.getAvailableMapFeatures().map(l => l.id));
  },

  [A.MAP_WIPE_NONSTATIC_DATA]({commit}, affectedFeatures) {
    commit(M.MAP_WIPE, affectedFeatures);
  },

  [A.MAP_TOGGLE_DRAW_MODE]({commit}, interactionHandler) {
    commit(M.MAP_TOGGLE_DRAW_MODE, interactionHandler);
  },

  [A.MAP_RESET_ORIENTATION]({commit}) {
    commit(M.MAP_RESET_ORIENTATION);
  },

  [A.MAP_REFRESH]({commit}) {
    commit(M.MAP_REFRESH);
  },

  [A.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE]({commit}, message) {
    commit(M.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE, message);
  },

  [A.ENABLE_PARTIAL_SUBSCRIBE]({commit}) {
    commit(M.ENABLE_PARTIAL_SUBSCRIBE);
  },

  [A.DISABLE_PARTIAL_SUBSCRIBE]({commit}) {
    commit(M.DISABLE_PARTIAL_SUBSCRIBE);
  },

  [A.MAP_ADD_FORM_LAYER]({commit}, formVectorLayer) {
    commit(M.MAP_ADD_FORM_LAYER, formVectorLayer);
  },

  [A.MAP_ADD_FORM_GEOMETRY_TO_LAYER]({commit}, formGeometriesConfig) {
    commit(M.MAP_ADD_FORM_GEOMETRY_TO_LAYER, formGeometriesConfig);
  },

  [A.MAP_REMOVE_ALL_LAYERS]({commit}, formLayerIds) {
    commit(M.MAP_REMOVE_ALL_LAYERS, formLayerIds);
  },

  [A.MAP_DESELECT_ALL_FEATURES]({commit}) {
    commit(M.MAP_DESELECT_ALL_FEATURES);
  },

  [A.MAP_SET_CLICKED_FEATURES]({commit}, features) {
    commit(M.MAP_SET_CLICKED_FEATURES, features);
  }
};

const mutations = {

  async [M.UPDATE_BASE_MAP](state, background) {
    if (state.map) {
      const backgroundLayer = await LayerUtils.createBaseMapLayer(background);

      const currentBackgroundLayer = state.map.getLayers().getArray().find(layer => layer.get('id') === 'base');
      if (currentBackgroundLayer != null) {
        state.map.removeLayer(currentBackgroundLayer);
      }
      state.map.addLayer(backgroundLayer);
    }
  },

  [M.ENABLE_3D]() {
    console.error(i18n.global.t('errorMessages.3dNotSupportedError'));
  },

  [M.INIT_MAP_HANDLER](state, map) {
    state.map = map;
  },

  [M.MAP_ADD_MESSAGE](state, message) {
    if (message && state.map) {
      const layerSource = MapUtils.getMapLayerSourceById(message.getProperties().featureType);
      message.setGeometry(message.getGeometry().transform('EPSG:4326', 'EPSG:3857'));
      message.setId(message.getProperties().id);
      layerSource.addFeature(message);
    }
  },

  [M.MAP_REFRESH_LAYER_DATA_BY_ID](state, layerId) {
    const geoserverLayer = Store.getters.getGeoserverLayers.find(layer => layer.id === layerId);
    if (geoserverLayer) {
      const olLayer = state.map.getLayers().getArray().find(layer => layer.get('id') === geoserverLayer.id);
      if (olLayer) {
        if (geoserverLayer.dataFormat === DataFormat.WMS && olLayer.getSource().updateParams) {
          olLayer.getSource().updateParams(LayerUtils.getWmsLayerSourceParams(geoserverLayer));
        }
        olLayer.getSource().refresh();
      }
    }
  },

  [M.MAP_ADD_GEOSERVER_LAYERS](state) {
    const geoserverLayers = Store.getters.getGeoserverLayers
      .filter(layer => Store.getters.getFeaturesConfiguredForCurrentView.includes(layer.id));
    geoserverLayers.forEach(async (layer) => {
      const mapLayer = await LayerUtils.createGeoserverLayer(layer);
      state.map.addLayer(mapLayer);
    });
  },

  [M.MAP_MESSAGE_CLEANUP](state, messagesToRemove) {
    messagesToRemove
      .filter(message => Features.getAvailableMapFeatures().map(l => l.id).includes(message.featureType))
      .forEach(message => {
        const mapLayers = state.map.getLayers().getArray();
        MapUtils.removeMessageLayersFromMap(mapLayers, message);
        MapUtils.removeAssociatedMessageLayersFromMap(mapLayers, message);
      });
  },

  [M.MAP_REMOVE_FEATURES](state, layerIds) {
    const layers = state.map.getLayers();
    layers.forEach(layer => {
      if (layerIds.includes(layer.get('id'))) {
        const layerSource = MapUtils.getSourceOfLayer(layer);
        layerSource.clear();
      }
    });
  },

  [M.MAP_CHANGE_LAYER_VISIBILITY](state, layerInfo) {
    if (Features.getAvailableMapFeatures().map(l => l.id).includes(layerInfo.id) ||
      Store.getters.getGeoserverLayers.map(l => l.id).includes(layerInfo.id)) {
      const layer = state.map.getLayers().getArray().find(layer => layer.get('id') === layerInfo.id);
      layer.setVisible(!layer.getVisible());
    }
  },

  [M.REFRESH_LAYER](state, layerConfig) {
    if (state.map) {
      const styleFunction = StyleFunctionService.createStyleFunction(layerConfig);
      const layer = state.map.getLayers().getArray().find(layer => layer.get('id') === layerConfig.layer);
      if (layer) {
        layer.setStyle(styleFunction);
      }
    }
  },

  [M.MAP_VIEWPORT_SNAPSHOT](state, snapshot) {
    state.snapshot = snapshot;
  },

  [M.MAP_WIPE](state, layerIds) {
    if (state.map) {
      state.map.getLayers().getArray()
        .filter(l => layerIds.indexOf(l.get('id')) !== -1)
        .forEach(layer => {
          MapUtils.getSourceOfLayer(layer).clear();
        });
    }
  },

  [M.FLY_TO_FEATURE](state, params) {
    const view = state.map.getView();
    const locationToFly = fromLonLat([params.location[0], params.location[1]]);
    if (OpGeometryUtils.areCoordinatesNotANumber(locationToFly) ||
      OpGeometryUtils.geometryCentersAreEqual(view.getCenter(), locationToFly)) {
      params.onComplete();
      return;
    }
    let currentZoom = view.getZoom();
    const zoom = Math.min(currentZoom, params.zoomInLevel, 15);
    if (OpGeometryUtils.getIntersectionBetweenPointAndCurrentViewPort(new Point(locationToFly))) {
      view.animate({
        center: locationToFly,
        zoom: params.zoomInLevel,
        duration: AppConfig.ui.flyToFeatureDuration / 2
      }, params.onComplete);
    } else {
      view.animate({
        center: locationToFly,
        duration: AppConfig.ui.flyToFeatureDuration
      }, params.onComplete);
      view.animate({
        zoom: zoom - 1,
        duration: AppConfig.ui.flyToFeatureDuration / 2
      }, {
        zoom: params.zoomInLevel,
        duration: AppConfig.ui.flyToFeatureDuration / 2
      });
    }
  },

  [M.ZOOM_TO_VIEWPORT](state, viewPort) {
    if (state.map) {
      const extent4326 = [
        Math.min(viewPort.upperLeft.lon, viewPort.bottomRight.lon),
        Math.min(viewPort.bottomRight.lat, viewPort.upperLeft.lat),
        Math.max(viewPort.upperLeft.lon, viewPort.bottomRight.lon),
        Math.max(viewPort.bottomRight.lat, viewPort.upperLeft.lat)];

      const extent3867 = ProjectionConverter.convertTo3857(extent4326);

      state.map.getView().fit(extent3867);
      if (viewPort.zoom) {
        state.map.getView().setZoom(viewPort.zoom);
      }
    }
  },

  [M.MAP_RESET_ORIENTATION](state) {
    const view = state.map.getView();
    view.setRotation(0);
  },

  [M.ZOOM_TO_EXTENT](state, extent) {
    state.map.getView().fit(extent);
  },

  [M.MAP_TOGGLE_DRAW_MODE](state, interactionHandler) {
    if (state.interactionHandler) {
      state.map.removeInteraction(state.interactionHandler);
      state.interactionHandler = null;
    }

    if (interactionHandler) {
      state.map.addInteraction(interactionHandler);
      state.interactionHandler = interactionHandler;
    }
  },

  [M.MAP_REFRESH](state) {
    if (state.map) {
      setTimeout(() => state.map.updateSize(), 1);
    }
  },

  [M.MAP_TOGGLE_OVERLAY_EDIT_MESSAGE](state, message) {
    state.showOverlayEditMessage = !!message;
    state.editMessageText = message;
  },

  [M.ENABLE_PARTIAL_SUBSCRIBE](state) {
    state.enablePartialSubscribe = true;
  },

  [M.DISABLE_PARTIAL_SUBSCRIBE](state) {
    state.enablePartialSubscribe = false;
  },

  [M.MAP_ADD_FORM_LAYER](state, formVectorLayer) {
    state.map.addLayer(formVectorLayer);
  },

  [M.MAP_ADD_FORM_GEOMETRY_TO_LAYER](state, formGeometriesConfig) {
    const layer = state.map.getLayers().getArray()
      .find(layer => layer.get('id') === formGeometriesConfig.layerId);
    if (layer && formGeometriesConfig.geometries) {
      formGeometriesConfig.geometries
        .map(geometry =>
          new Feature({
            id: `${formGeometriesConfig.layerId}-${Date.now()}`,
            geometry: geometry.clone().transform('EPSG:4326', 'EPSG:3857'),
          })
        )
        .forEach(feature => layer.getSource().addFeature(feature));
    }
  },

  [M.MAP_REMOVE_ALL_LAYERS](state, layerIds) {
    if (layerIds && layerIds.length > 0 && state.map) {
      state.map.getLayers().getArray()
        .filter(currentLayer => layerIds.includes(currentLayer.get('id')))
        .forEach(currentLayer => state.map.removeLayer(currentLayer));
    }
  },

  [M.MAP_DESELECT_ALL_FEATURES](state) {
    const mapLayers = state.map.getLayers().getArray();
    mapLayers.forEach(layer => {
      if (layer.get('id') !== 'base' && !LayerUtils.isGeoserverLayer(layer.get('id'))) {
        const layerSource = MapUtils.getSourceOfLayer(layer);
        layerSource?.getFeatures()?.forEach(feature => feature.setProperties({selected: false}));
      }
    });
  },

  [M.MAP_SET_CLICKED_FEATURES](state, features) {
    state.clickedMessages = features;
  },

};

function associatedFeatureExists(state, operationPlan, message) {
  let associatedFeatureLayerConfigs = MapUtils.getLayerConfigForFeatureType(operationPlan[0].featureType);
  associatedFeatureLayerConfigs = associatedFeatureLayerConfigs ?
    associatedFeatureLayerConfigs.filter(layerConfig => layerConfig.id === message.featureType) : [];
  if (associatedFeatureLayerConfigs && associatedFeatureLayerConfigs.length !== 0) {
    const featureConfig = associatedFeatureLayerConfigs[0];
    const layerSource = MapUtils.getMapLayerSourceById(featureConfig.id);
    const alreadyExistingFeature = layerSource ? layerSource.getFeatures()
      .filter(feat => featureConfig.getAssociatedFeatureId(feat) === operationPlan[0].id) : [];
    return alreadyExistingFeature.length !== 0;
  }
  return false;
}

const getters = {
  partialSubscribeIsEnabled: state => {
    return state.enablePartialSubscribe;
  },
  associatedFeatureExists: (state) => (operationPlan, message) => {
    return associatedFeatureExists(state, operationPlan, message);
  },
  getCurrentViewPortPolygon: state => {
    const mapExtent = state.map.getView().calculateExtent(state.map.getSize());
    return fromExtent(mapExtent);
  },
  getAllMapLayers: (state) => {
    return state.map.getLayers().getArray();
  },
  getClickedFeatures: (state) => {
    return state.clickedMessages;
  },
  getMap: (state) => {
    return state.map;
  },
  getInteractionHandler: (state) => {
    return state.interactionHandler;
  },
  getCurrentView: (state) => {
    return state.map.getView();
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};