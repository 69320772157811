import A from '../../constants/actions';
import M from '../../constants/mutations';
import Map from "ol/Map";
import {defaults as defaultInteractions, DragRotateAndZoom} from "ol/interaction";
import View from "ol/View";
import BackgroundConfig from "../../config/backgroundConfig";
import Features from "../../config/features";
import FeatureType from "../../constants/featureType";
import FeatureUtils from "../../utils/FeatureUtils";
import OperationPlanState from "../../constants/operationPlanState";
import {fromLonLat} from "ol/proj";
import {getCenter} from "ol/extent";
import Store from '../../store';
import StyleFunctionService from '../../styleFunction/styleFunctionService';
import {Vector} from "ol/source";
import VectorSource from "ol/source/Vector";
import LayerUtils from "../../utils/LayerUtils";
import LayersWithMultipleVolumesConfig from "../../config/LayersWithMultipleVolumesConfig";
import MultiPolygon from "ol/geom/MultiPolygon";
import MapUtils from "../../utils/MapUtils";
import VectorImage from "ol/layer/VectorImage";

const EMBEDDED_MAP_CONTAINER_ID = 'embedded-map';

const CLOSED_OPERATION_PLAN_FEATURE_TYPE = 'operationplan_closed';

const OUTLOOK_OPERATION_PLAN_DEFAULT_STYLING_CONFIG = {
  layer: FeatureType.OPERATION_PLAN_OUTLOOK,
  showText: true,
  textSize: 14,
  iconSize: 10,
  colors: {
    borderColor: {r: 131, g: 80, b: 46, a: 1},
    fillColor: {r: 131, g: 80, b: 46, a: 0.75},
    textColor: {r: 255, g: 255, b: 255, a: 1},
    textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
    imageColor: {r: 131, g: 80, b: 46, a: 1},
  }
};

const OUTLOOK_GEOZONE_DEFAULT_STYLING_CONFIG = {
  layer: FeatureType.ED269_OUTLOOK,
  showText: true,
  textSize: 14,
  iconSize: 10,
  colors: {
    borderColor: {r: 248, g: 231, b: 28, a: 1},
    fillColor: {r: 248, g: 231, b: 28, a: 0.1},
    textColor: {r: 255, g: 255, b: 255, a: 1},
    textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
    imageColor: {r: 248, g: 231, b: 28, a: 1},
  }
};

const CLOSED_OPERATION_PLAN_DEFAULT_STYLING_CONFIG = {
  layer: FeatureType.OPERATION_PLAN_OUTLOOK,
  showText: true,
  textSize: 14,
  iconSize: 10,
  colors: {
    borderColor: {r: 120, g: 120, b: 120, a: 1},
    fillColor: {r: 120, g: 120, b: 120, a: 0.75},
    textColor: {r: 255, g: 255, b: 255, a: 1},
    textBackgroundColor: {r: 0, g: 0, b: 0, a: 1},
    imageColor: {r: 120, g: 120, b: 120, a: 1},
  }
};

const state = {
  mapContainerId: EMBEDDED_MAP_CONTAINER_ID,
  mapInstance: null,
  mapBackground: BackgroundConfig.backgrounds[0],
  isDisplayed: false,
  messageIds: [],
  currentHighlightedFeatureProps: {
    id: null,
    featureType: null
  }
};

const actions = {
  async [A.EMBEDDED_MAP_TOGGLE_DISPLAY]({commit, state}, payload) {
    if (!state.isDisplayed) {
      const basemapLayers = [...BackgroundConfig.backgrounds, ...Store.getters.getBaseMapLayers];
      const selectedBasemap = basemapLayers.find((basemap) => basemap.id === payload.backgroundId);
      const basemapLayer = await LayerUtils.createBaseMapLayer(selectedBasemap ? selectedBasemap : basemapLayers[0]);
      commit(M.EMBEDDED_MAP_DISPLAY);
      commit(M.EMBEDDED_MAP_INITIALIZE, payload);
      commit(M.EMBEDDED_MAP_SET_BACKGROUND, basemapLayer);
      commit(M.EMBEDDED_MAP_MESSAGE_CLEANUP);
      commit(M.EMBEDDED_MAP_DISPLAY_MESSAGES, payload.messages);
      commit(M.EMBEDDED_MAP_HIGHLIGHT_TARGET_MESSAGE, payload);
      commit(M.EMBEDDED_MAP_RERENDER);
    } else {
      commit(M.EMBEDDED_MAP_HIDE);
      commit(M.EMBEDDED_MAP_MESSAGE_IDS_AND_HIGHLIGHT_FEATURE_PROPS_CLEANUP);
    }
  },

  [A.EMBEDDED_MAP_DESTROY]({commit}) {
    commit(M.EMBEDDED_MAP_DESTROY);
  },

  [A.EMBEDDED_MAP_UPDATE_BACKGROUND]({commit}, backgroundId) {
    commit(M.EMBEDDED_MAP_SET_BACKGROUND, backgroundId);
  },

  [A.EMBEDDED_MAP_HIGHLIGHT_TARGET_MESSAGE]({commit}, payload) {
    commit(M.EMBEDDED_MAP_HIGHLIGHT_TARGET_MESSAGE, payload);
  },

  [A.EMBEDDED_MAP_NEW_MESSAGE_HANDLER]({commit, state}, config) {
    const newMessage = config.newMessage;
    const isReceivedMessageDisplayedOnEmbeddedMap = state.messageIds.some(id => id === newMessage.id);
    const isNewMessageToBeDisplayed = !state.messageIds.find(id => id === newMessage.id) &&
      newMessage.featureType.startsWith(state.currentHighlightedFeatureProps.featureType);
    if (isReceivedMessageDisplayedOnEmbeddedMap) {
      commit(M.EMBEDDED_MAP_REMOVE_DISPLAYED_MESSAGE, config.toRemove);
      commit(M.EMBEDDED_MAP_DISPLAY_MESSAGES, [newMessage]);
    }
    if (isNewMessageToBeDisplayed) {
      commit(M.EMBEDDED_MAP_DISPLAY_MESSAGES, [newMessage]);
    }
  },
};

const mutations = {
  [M.EMBEDDED_MAP_INITIALIZE](state, payload) {
    if (!state.mapInstance) {
      const mapLayers = getMapLayers(payload.featureType);
      state.mapInstance = new Map({
        interactions: defaultInteractions({
          altShiftDragRotate: false,
          pinchRotate: false
        }).extend([
          new DragRotateAndZoom()
        ]),
        layers: mapLayers,
        target: state.mapContainerId,
        view: new View({
          center: [0, 0],
          zoom: 2,
        })
      });
      state.mapInstance.updateSize();
    }
  },

  async [M.EMBEDDED_MAP_SET_BACKGROUND](state, backgroundLayer) {
    if (state.isDisplayed) {
      state.mapInstance.addLayer(backgroundLayer);
    }
  },

  [M.EMBEDDED_MAP_MESSAGE_CLEANUP](state) {
    clearAllMessagesDisplayedOnMap(state.mapInstance);
  },

  [M.EMBEDDED_MAP_DISPLAY_MESSAGES](state, messages) {
    messages.forEach(msg => {
      const index = state.messageIds.indexOf(msg.id);
      if (index === -1) {
        state.messageIds.push(msg.id);
      }
    });
    messages.forEach(m => plotMessageToMap(state.mapInstance, m));
  },

  [M.EMBEDDED_MAP_HIGHLIGHT_TARGET_MESSAGE](state, payload) {
    if (state.mapInstance) {
      state.currentHighlightedFeatureProps = {
        id: payload.targetMessage.id,
        featureType: payload.featureType
      };
      if (LayerUtils.isLayerWithMultipleVolumes(payload.targetMessage.featureType)) {
        highlightMultipleVolumeTargetMessage(state.mapInstance, payload.targetMessage);
      } else {
        highlightTargetMessage(state.mapInstance, payload.targetMessage);
      }
    }
  },

  [M.EMBEDDED_MAP_RERENDER](state) {
    state.mapInstance.setTarget(null);
    state.mapInstance.setTarget(state.mapContainerId);
  },

  [M.EMBEDDED_MAP_DISPLAY](state) {
    state.isDisplayed = true;
  },

  [M.EMBEDDED_MAP_HIDE](state) {
    state.isDisplayed = false;
  },

  [M.EMBEDDED_MAP_DESTROY](state) {
    state.mapInstance = null;
  },

  [M.EMBEDDED_MAP_REMOVE_DISPLAYED_MESSAGE](state, toRemove) {
    const mapMessagesToRemove = toRemove.filter(message => Features.isFeatureTypeDisplayableOnMap(message.featureType));
    mapMessagesToRemove.forEach(message => {
      const mapLayers = state.mapInstance.getLayers().getArray();
      MapUtils.removeMessageLayersFromMap(mapLayers, message);
    });
  },

  [M.EMBEDDED_MAP_MESSAGE_IDS_AND_HIGHLIGHT_FEATURE_PROPS_CLEANUP](state) {
    state.messageIds = [];
    state.currentHighlightedFeatureProps = {
      id: null,
      featureType: null
    };
  }
};

const getters = {
  getEmbeddedMapContainerId: (state) => {
    return state.mapContainerId;
  },
  getEmbeddedMapLayers: (state) => {
    return state.mapInstance?.getLayers().getArray();
  },
};

function getMapLayers(featureType) {
  const features = Features.getAvailableFeatures().filter(f => f.id.includes(featureType));
  features.push({
    id: CLOSED_OPERATION_PLAN_FEATURE_TYPE,
    zIndex: 1
  });
  return features.map(feature => {
    if (feature.id.startsWith(FeatureType.OPERATION_PLAN)) {
      return MapUtils.constructClusteredLayerWithStyle(feature, getLayerDefaultStyle(feature.id));
    }
    const vectorLayer = new VectorImage({
      source: new VectorSource(),
      style: getLayerDefaultStyle(feature.id)
    });
    vectorLayer.set('id', feature.id);
    vectorLayer.setZIndex(feature.zIndex);
    return vectorLayer;
  });
}

function clearAllMessagesDisplayedOnMap(mapInstance) {
  mapInstance.getLayers().getArray()
    .map(layer => MapUtils.getSourceOfLayer(layer))
    .filter(s => s instanceof Vector).forEach(v => v.clear());
}

function getStylingConfig(featureType) {
  return Store.state.themeStore.currentTheme.stylingConfigs.find(s => s.layer === featureType);
}

function getLayerDefaultStyle(featureType) {
  if (featureType === FeatureType.OPERATION_PLAN_OUTLOOK) {
    return StyleFunctionService.createStyleFunction(OUTLOOK_OPERATION_PLAN_DEFAULT_STYLING_CONFIG, true);
  }
  if (featureType === CLOSED_OPERATION_PLAN_FEATURE_TYPE) {
    return StyleFunctionService.createStyleFunction(CLOSED_OPERATION_PLAN_DEFAULT_STYLING_CONFIG, true);
  }
  if (featureType === FeatureType.ED269_OUTLOOK) {
    return StyleFunctionService.createStyleFunction(OUTLOOK_GEOZONE_DEFAULT_STYLING_CONFIG, true);
  }
  return StyleFunctionService.createStyleFunction(getStylingConfig(featureType), true);
}

function plotMessageToMap(mapInstance, message) {
  let layer = getLayerByState(mapInstance, message);
  const featureConfig = Features.getFeature(FeatureUtils.Mapping.getFeatureType(message));
  if (LayerUtils.isLayerWithMultipleVolumes(message.featureType)) {
    addFeatureWithMultipleVolumesToMap(message, featureConfig, layer);
  } else {
    const messageMapFeature = FeatureUtils.Mapping.mapFeatureToGeoJson(message, featureConfig);
    addMessageToMap(layer, messageMapFeature);
  }
}

function addFeatureWithMultipleVolumesToMap(message, featureConfig, layer) {
  const multipleVolumesField = LayersWithMultipleVolumesConfig.getEmbeddedMapMultipleVolumesField(message.featureType);
  let volumes = getVolumes(message, multipleVolumesField);
  volumes.forEach((volume, index) => {
    const olFeature = FeatureUtils.Mapping.mapFeatureToVolumeGeoJson(message, volume, featureConfig, index);
    addMessageToMap(layer, olFeature);
  });
}

function getVolumes(message, path) {
  let current = message;
  path.split('.').forEach(function (p) {
    current = current[p];
  });
  return current;
}

function addMessageToMap(layer, olMessageMapFeature) {
  if (layer && olMessageMapFeature) {
    olMessageMapFeature.setGeometry(olMessageMapFeature.getGeometry().transform('EPSG:4326', 'EPSG:3857'));
    olMessageMapFeature.setId(olMessageMapFeature.getProperties().id);
    MapUtils.getSourceOfLayer(layer).addFeature(olMessageMapFeature);
  }
}

function getLayerByState(mapInstance, message) {
  if (message.state === OperationPlanState.CLOSED.state) {
    return mapInstance.getLayers().getArray().find(layer => layer.get('id') === CLOSED_OPERATION_PLAN_FEATURE_TYPE);
  } else {
    return mapInstance.getLayers().getArray().find(layer => layer.get('id') === message.featureType);
  }
}

function highlightTargetMessage(mapInstance, targetMessage) {
  const targetFeature = getMapFeature(mapInstance, targetMessage);
  if (targetFeature && targetFeature.getGeometry()) {
    const targetFeatureLocation = getMapFeatureLocation(targetFeature.getGeometry());
    const targetFeatureZoomInLevel = getMapFeatureZoomInLevel(mapInstance.getView(), targetFeature.getGeometry());
    targetFeature.setProperties({selected: true});
    mapInstance.getView().setCenter(targetFeatureLocation);
    mapInstance.getView().setZoom(targetFeatureZoomInLevel);
  }
}

function highlightMultipleVolumeTargetMessage(mapInstance, targetMessage) {
  const volumeFeatures = getTargetMessageLayerSource(mapInstance, targetMessage)
    .getFeatures().filter(f => f.getProperties().associatedFeatureId === targetMessage.id);
  const multiPolygon = new MultiPolygon([]);
  volumeFeatures.map(f => f.getGeometry()).forEach(polygon => multiPolygon.appendPolygon(polygon));
  const targetFeatureLocation = getMapFeatureLocation(multiPolygon);
  const targetFeatureZoomLevel = getMapFeatureZoomInLevel(mapInstance.getView(), multiPolygon);
  volumeFeatures.forEach(v => {
    v.setProperties({selected: true});
  });
  mapInstance.getView().setCenter(targetFeatureLocation);
  mapInstance.getView().setZoom(targetFeatureZoomLevel);
}

function getMapFeature(mapInstance, targetMessage) {
  const targetMessageLayer = getTargetMessageLayerSource(mapInstance, targetMessage);
  return targetMessageLayer ? targetMessageLayer.getFeatureById(targetMessage.id) : null;
}

function getTargetMessageLayerSource(mapInstance, targetMessage) {
  const mapLayer = mapInstance
    .getLayers()
    .getArray()
    .find(layer => layer.get('id') === getFeatureType(targetMessage));
  return MapUtils.getSourceOfLayer(mapLayer);
}

function getMapFeatureLocation(featureGeometry) {
  const location = getCenter(featureGeometry.clone().transform('EPSG:3857', 'EPSG:4326').getExtent());
  return fromLonLat([location[0], location[1]]);
}

function getMapFeatureZoomInLevel(mapCurrentView, featureGeometry) {
  const resolutionForExtent = mapCurrentView.getResolutionForExtent(featureGeometry.getExtent());
  return Math.round(mapCurrentView.getZoomForResolution(resolutionForExtent)) - 1;
}

function getFeatureType(message) {
  return message.state === OperationPlanState.CLOSED.state ? CLOSED_OPERATION_PLAN_FEATURE_TYPE : message.featureType;
}

export default {
  state,
  actions,
  mutations,
  getters
}