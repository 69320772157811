import LogLevels from '../constants/logLevels';
import GeozoneConfigUtils from "../utils/geozones/GeozoneConfigUtils";
import heightSlider from "../config-files/height-slider.json"
import topics from "../config-files/topics.json"
import groupedLayerSelectors from "../config-files/grouped-layer-selectors.json"
import droneIdentificationTypesConfig from "./droneIdentificationTypesConfig";

function getEnv(name, validationFunction) {
  let value;
  if (window.configs !== undefined && window.configs[name] !== undefined) {
    value = window.configs[name];
  } else {
    value = process.env[name];
  }
  if (validationFunction !== undefined) {
    return validationFunction(value);
  }
  return value;
}

const secured = getEnv("VUE_APP_SECURED") === 'true';
const wsLabel = 'ws' + (secured ? 's' : '');
const httpLabel = 'http' + (secured ? 's' : '');

export default {
  build: '819',
  version: '3.22.2',
  server: {
    websocketUrl: wsLabel + '://' + getEnv("VUE_APP_SERVER_URL") + '/camel/smartsis',
    serviceUrlRoot: httpLabel + '://' + getEnv("VUE_APP_SERVER_URL"),
    queryServiceURL: httpLabel + '://' + getEnv("VUE_APP_SERVER_URL") + process.env.VUE_APP_QUERY_SERVICE_URL,
  },
  ui: {
    branding: process.env.VUE_APP_UI_BRANDING,
    showDirectoryPanel: process.env.VUE_APP_UI_SHOW_DIRECTORY_PANEL === 'true',
    highlightTimeout: parseInt(process.env.VUE_APP_UI_HIGHLIGHT_TIMEOUT),
    notificationPopupDurationSeconds: !isNaN(parseInt(getEnv('VUE_APP_UI_NOTIFICATION_POPUP_DURATION_SECONDS')))
      ? parseInt(getEnv('VUE_APP_UI_NOTIFICATION_POPUP_DURATION_SECONDS'))
      : 3,
    messageCleanUpInterval: 60 * 1000,
    mapOnlyFeatureCleanUpInterval: 60 * 1000,
    messageTimeoutCheckInterval: 30 * 1000,
    authRequired: true,
    soundNotificationTimeout: 1000,
    timeBetweenRenders: 10000,
    enableDocs: process.env.VUE_APP_UI_ENABLE_DOCS === 'true',
    enable3DMode: process.env.VUE_APP_UI_3D_MODE === 'true',
    enableThresholds: process.env.VUE_APP_UI_ENABLE_THRESHOLDS === 'true',
    enableTrainingMode: getEnv("VUE_APP_UI_ENABLE_TRAINING_MODE") === 'true',
    searchHistoryLength: parseInt(process.env.VUE_APP_UI_SEARCH_HISTORY_LENGTH),
    flyToFeatureDuration: 1000,
    progressBarColor: process.env.VUE_APP_UI_PROGRESS_BAR_COLOR,
    metarCriticalInfoOnly: process.env.VUE_APP_UI_METAR_CRITICAL_INFO_ONLY,
    zoomLevel: JSON.parse(process.env.VUE_APP_UI_ZOOM_LEVEL),
    heartbeatInterval: process.env.VUE_APP_HEARTBEAT_INTERVAL || 5,
    applicationZoomLevel: parseFloat(getEnv("VUE_APP_APPLICATION_ZOOM_LEVEL")) || 1,
    globalCrs: getEnv("VUE_APP_UI_GLOBAL_CRS"),
    displayNoiseLevel: getEnv("VUE_APP_UI_DISPLAY_NOISE_LEVEL") === 'true',
    defaultLanguage: getEnv("VUE_APP_UI_DISPLAY_LANGUAGE") !== '' ? getEnv("VUE_APP_UI_DISPLAY_LANGUAGE") :
      'eng',
    supportedLanguages: JSON.parse(getEnv("VUE_APP_UI_SUPPORTED_LANGUAGES")),
  },
  features: JSON.parse(process.env.VUE_APP_FEATURES),
  auth: {
    realm: process.env.VUE_APP_AUTH_REALM,
    url: httpLabel + '://' + getEnv("VUE_APP_AUTH_SERVICE_URL") + '/auth',
    sslRequired: process.env.VUE_APP_AUTH_SSL_REQUIRED,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    publicClient: true,
    idpHint: getEnv("VUE_APP_AUTH_IDP_HINT")
  },
  logLevel: LogLevels[process.env.VUE_APP_LOG_LEVEL.toLowerCase().trim()],
  topics: topics,
  groupedLayerSelectors: groupedLayerSelectors || [],
  heightSlider: heightSlider,
  timeSlider: process.env.VUE_APP_TIME_SLIDER === 'true',
  timeSliderLeftLimit: parseInt(process.env.VUE_APP_TIME_SLIDER_LEFT_LIMIT, 10),
  timeSliderRightLimit: parseInt(process.env.VUE_APP_TIME_SLIDER_RIGHT_LIMIT, 10),
  maxResolutionForText: parseInt(process.env.VUE_APP_MAX_RESOLUTION_FOR_TEXT),
  performDroneLabelDecluttering: getEnv('VUE_APP_PERFORM_DRONE_LABEL_DECLUTTERING') === 'true' || false,
  providerId: 'unknown',
  enableDefaultBaseMapConfigs: getEnv('VUE_APP_ENABLE_DEFAULT_BASE_MAP_CONFIGS') === 'true' || false,
  operationPlanRejectionReasons: getEnv('VUE_APP_OPERATION_PLAN_REJECTION_REASONS')?.split('||') || [],
  operationPlanAllowActivationBeforeStartMillis: getEnv('VUE_APP_ALLOW_ACTIVATION_BEFORE_START_SECONDS') * 1000,
  geozoneCountries: getEnv('VUE_APP_GEOZONE_COUNTRIES') !== '' ? getEnv('VUE_APP_GEOZONE_COUNTRIES')?.split('|') : ['FRQ'],
  geozoneAuthorityDetails: GeozoneConfigUtils.constructAuthorityDetails(getEnv('VUE_APP_GEOZONE_AUTHORITY_DETAILS')),
  adsbValidity: getEnv('VUE_APP_ADSB_VALIDITY_SECONDS') !== '' ? getEnv('VUE_APP_ADSB_VALIDITY_SECONDS') : 2,
  aircraftValidity: getEnv('VUE_APP_POSE_AIRCRAFT_VALIDITY_SECONDS') !== '' ? getEnv('VUE_APP_POSE_AIRCRAFT_VALIDITY_SECONDS') : 2,
  droneValidity: getEnv('VUE_APP_POSE_DRONE_VALIDITY_SECONDS') !== '' ? getEnv('VUE_APP_POSE_DRONE_VALIDITY_SECONDS') : 2,
  droneIdentificationTypes: getEnv('VUE_APP_DRONE_IDENTIFICATION_TYPES') ?
    getEnv('VUE_APP_DRONE_IDENTIFICATION_TYPES', droneIdentificationTypesConfig.validateDroneIdentificationTypes)
    : droneIdentificationTypesConfig.getDefaultDroneIdentificationTypes(),
  droneIdentificationOtherTypes: getEnv('VUE_APP_DRONE_IDENTIFICATION_OTHER_TYPES') ?
    getEnv('VUE_APP_DRONE_IDENTIFICATION_OTHER_TYPES', droneIdentificationTypesConfig.validateDroneIdentificationOtherTypes)
    : [],
  laraAirspaceClosedReservationValidity: getEnv('VUE_APP_LARA_AIRSPACE_CLOSED_RESERVATION_VALIDITY_HOURS') ?
    getEnv('VUE_APP_LARA_AIRSPACE_CLOSED_RESERVATION_VALIDITY_HOURS') : 12,
  icaoFplValidityInDays: getEnv('VUE_APP_ICAO_FPL_VALIDITY_DAYS') !== '' ? getEnv('VUE_APP_ICAO_FPL_VALIDITY_DAYS') : 2,
}