const SYSTEM_CONFIGURATION_IDS = {
  DISPLAY_CONFORMANT_DRONE: 'DISPLAY_CONFORMANT_DRONE',
  DISPLAY_RESOLVED_MESSAGE: 'DISPLAY_RESOLVED_MESSAGE',
  DISPLAY_UPCOMING_PLANS_ON_MAP: 'DISPLAY_UPCOMING_PLANS_ON_MAP',
  DISPLAY_RESTRICT_AOR: 'DISPLAY_RESTRICT_AOR',
  AIRSPACE_RESERVATION_FOR_HIGH_PRIORITY_PLANS: 'AIRSPACE_RESERVATION_FOR_HIGH_PRIORITY_PLANS',
  GENERAL_SOUND_NOTIFICATION: 'GENERAL_SOUND_NOTIFICATION'
}

const DISPLAY_CONFIGURATION_IDS = {
  HIGHLIGHT_INFO_PANEL_NEW_MESSAGE: 'HIGHLIGHT_INFO_PANEL_NEW_MESSAGE',
  HIGHLIGHT_INFO_PANEL_SELECTED_MESSAGE: 'HIGHLIGHT_INFO_PANEL_SELECTED_MESSAGE',
  DRONE_PLAN_UPCOMING_TAB: 'DRONE_PLAN_UPCOMING_TAB',
  TASKS_TAB: 'TASKS_TAB',
  TASKS_SUBTAB: 'TASKS_SUBTAB',
  DRONE_PLAN_SELECTED_MESSAGE_PIN: 'DRONE_PLAN_SELECTED_MESSAGE_PIN'
}

const GEOZONE_CONFIGURATION_IDS = {
  CONDITIONAL: 'CONDITIONAL',
  NO_RESTRICTION: 'NO_RESTRICTION',
  PROHIBITED: 'PROHIBITED',
  REQ_AUTHORISATION: 'REQ_AUTHORISATION'
}

const CONFIG_ID_DISPLAY_VALUE_MAPPINGS = {
  [DISPLAY_CONFIGURATION_IDS.HIGHLIGHT_INFO_PANEL_NEW_MESSAGE]:
    'labels.configurationPanel.global.newItem',
  [DISPLAY_CONFIGURATION_IDS.HIGHLIGHT_INFO_PANEL_SELECTED_MESSAGE]:
    'labels.configurationPanel.global.selectedItem',
  [DISPLAY_CONFIGURATION_IDS.DRONE_PLAN_UPCOMING_TAB]:
    'labels.configurationPanel.global.upcomingTab',
  [DISPLAY_CONFIGURATION_IDS.TASKS_TAB]:
    'labels.configurationPanel.global.tasksTab',
  [DISPLAY_CONFIGURATION_IDS.TASKS_SUBTAB]:
    'labels.configurationPanel.global.tasksSubTab',
  [DISPLAY_CONFIGURATION_IDS.DRONE_PLAN_SELECTED_MESSAGE_PIN]:
    'labels.configurationPanel.global.selectedDronePlanPin',
  [SYSTEM_CONFIGURATION_IDS.DISPLAY_CONFORMANT_DRONE]:
    'labels.configurationPanel.global.displayConformantDrone',
  [SYSTEM_CONFIGURATION_IDS.DISPLAY_RESOLVED_MESSAGE]:
    'labels.configurationPanel.global.displayResolvedMessage',
  [SYSTEM_CONFIGURATION_IDS.DISPLAY_UPCOMING_PLANS_ON_MAP]:
    'labels.configurationPanel.global.displayUpcomingPlansOnMap',
  [SYSTEM_CONFIGURATION_IDS.DISPLAY_RESTRICT_AOR]: 'labels.icaoLocations.restrictAor',
  [SYSTEM_CONFIGURATION_IDS.AIRSPACE_RESERVATION_FOR_HIGH_PRIORITY_PLANS]:
    'labels.configurationPanel.global.airspaceReservationForHighPriorityPlans',
  [SYSTEM_CONFIGURATION_IDS.GENERAL_SOUND_NOTIFICATION]: 'labels.configurationPanel.global.generalSoundNotification',
  [GEOZONE_CONFIGURATION_IDS.CONDITIONAL]: 'labels.configurationPanel.global.conditional',
  [GEOZONE_CONFIGURATION_IDS.NO_RESTRICTION]: 'labels.configurationPanel.global.noRestriction',
  [GEOZONE_CONFIGURATION_IDS.PROHIBITED]: 'labels.configurationPanel.global.prohibited',
  [GEOZONE_CONFIGURATION_IDS.REQ_AUTHORISATION]: 'labels.configurationPanel.global.reqAuthorisation',
}

const CONFIG_ID_OPTIONS_VALUE_MAPPINGS = {
  [SYSTEM_CONFIGURATION_IDS.AIRSPACE_RESERVATION_FOR_HIGH_PRIORITY_PLANS]: [
    {
      value: 'REQUIRED',
      label: 'labels.common.required'
    },
    {
      value: 'OPTIONAL',
      label: 'labels.common.optional'
    },
    {
      value: 'NONE',
      label: 'labels.common.none'
    }]
}

export default {
  SYSTEM_CONFIGURATION_IDS,
  DISPLAY_CONFIGURATION_IDS,
  GEOZONE_CONFIGURATION_IDS,
  CONFIG_ID_DISPLAY_VALUE_MAPPINGS,
  CONFIG_ID_OPTIONS_VALUE_MAPPINGS
}